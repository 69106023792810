.signInForm{
    padding:20% 20% 10% 20%;
}
.input{
    height: 45px;
    box-shadow: none !important;
}
.input::placeholder{
    font-size: 14px;
    color: #999999;
    font-weight: 400;
}
.signInForm .form-check-label{
    color: #666666;
    font-size: 14px;
    font-weight: 500;
}
.signInForm .forget-password{
    color: #666666;
    font-weight: 500;
    font-size: 13px;
}
.signIn-heading{
    font-size: 27px;
    font-weight: 600;
}
.login-btn{
    font-size: 20px;
    font-weight: 600;
}
.no-account{
    color: #666666;
    font-size: 12px;
    font-weight: 400;
}
.business-category-select >div{
    min-height: 45px;
    font-size: 14px;
}
.form-error{
    font-size: 12px;
}
.error-input,.error-input:hover,.error-input:focus{
    border: 1px solid red;
}
