.subscribe-form{
    width: 30em !important;
}

@media only screen and (max-width:450px){
    .subscribe-form{
        width: 100% !important;
    }
}

