.merchant-side-nav {
    height: 100vh;

    .active {
        background-color: #bfecc140;
        color: #00b207 !important;
        font-weight: 500;
    }
    .nav-link:hover,
    .accordion-button:hover {
        background-color: #bfecc140;
    }
    .nav-link,
    .accordion-button {
        color: #808080;
        font-weight: 500;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        gap: 5px;
        padding: 15px;
    }
    .nav-icon {
        font-size: 20px;
    }
    .accordion-button {
        padding-left: 14px;
    }
    @media screen and (max-width: 500px) {
        .nav-link,
        .accordion-button {
            padding-left: 5px;
        }
    }
    .collapse-btn {
        border: none;
        background-color: white;
        font-size: 30px;
        color: #00b207;
    }
    .collapse {
        transition: all 0.5s ease-in-out;
    }
}
