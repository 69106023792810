.star-rating {
    display: flex;
    justify-content: space-between;
    max-width: 58.09px;
}

.star {
    width: 11.62px !important;
}

.star.checked {
    color: #ffd700; /* Gold color for selected stars */
}

.star.unchecked {
    color: #dddddd; /* Grey color for unselected stars */
}
