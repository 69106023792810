.order-details-heading {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
}
.order-details-header,.card-header {
    background-color: transparent;
}
.order-details-date,
.order-details-item-no {
    color: #4d4d4d;
    font-size: 14px;
}
.dot {
    height: 6px;
    width: 6px;
    background-color: #4d4d4d;
    border-radius: 9999px;
}
.order-details-address-card {
    padding-bottom: 1.5em;
    .user-name {
        font-size: 14px;
        font-weight: 500;
        color: #1a1a1a;
        text-transform: capitalize;
    }
    .order-address {
        color: #808080;
        font-size: 12px;
    }
    .card-header {
        color: #999999;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        font-family: "Poppins";
        letter-spacing: 3%;
    }
    .email-head,
    .phone-head {
        color: #999999;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        padding-bottom: 0px;
        /* line-height: 12px; */
    }
    .email,
    .phone {
        font-size: 13px;
        margin-top: -15px;
        color: #1a1a1a;
        font-family: "Poppins";
        font-weight: 400;
    }
    .phone {
        font-size: 12px;
    }
}
.order-payment-card {
    padding-bottom: 1.5em;
    .order-id-head {
        color: #999999;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
    }
    .order-id {
        font-size: 13px;
        color: #1a1a1a;
        font-weight: 400;
    }
    .subtotal-head {
        color: #666666;
        font-size: 13px;
        font-weight: 400;
    }
    .subtotal {
        color: #1a1a1a;
        font-size: 13px;
        font-weight: 500;
    }
    .total-head{
        font-size: 18px;
        color: #1A1A1A;
    }
    .total{
        font-size: 18px ;
        font-weight: 600;
    }
}
