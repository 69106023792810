.customer-payment-table {
    font-size: 13px;
    font-weight: 500;
    th {
        font-weight: 500;
        color: #757575;
        padding: 15px 0px;
        font-size: 12px;
    }
    .form-check-input {
        height: 20px;
        width: 20px;
    }
    .order-id {
        font-weight: 600;
        color: #00b207;
    }
    .fade-color {
        color: #667085;
    }
    .other-product,
    .customer-mail {
        font-size: 11px;
        font-weight: 400;
    }
    .action a {
        color: #667085;
        font-size: 15px;
    }
    .order-column {
        padding-right: 15px;
        padding-left: 0px;
    }
    .form-select{
        box-shadow: none;
    }
}
