.account-settings-form{
    .input{
        font-size: 14px !important;
        color: #666666 !important;
    }
}

.account-settings-image{
    height: 10em;
    width: 10em;
    border-radius: 9999px;
}
.custom-file-upload{
    font-weight: 600;
    font-size: 13px;
    border-width: 1.5px;
}
.custom-file-upload:hover{
    color: white;
}