.merchant-product-list {
    .btn {
        font-weight: 600;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        gap: 5px;
        padding: 10px 15px;
    }
    .btn svg {
        font-size: 20px;
    }
    .export-btn {
        background-color: #bfecc1;
        color: #00b207;
    }
    .search-input {
        font-size: 14px;
    }
    .search-input::placeholder {
        color: #858d9d;
    }
    .search-icon {
        font-size: 20px;
        color: #667085;
    }
}
.product-form {
    .btn {
        font-weight: 600;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        gap: 5px;
        padding: 10px 15px;
    }
    .form-label {
        color: #4d5464;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0px;
    }
    .form-control::placeholder {
        color: #858d9d;
        font-size: 12px;
    }
    .form-control,
    .form-select {
        font-size: 13px;
        background-color: #f9f9fc;
        box-shadow: none;
    }
    /* Targeting the control on focus */
    .product-tags .react-select__control--is-focused {
        border-color: transparent; /* Remove the border color */
        box-shadow: none; /* Remove the box-shadow if present */
    }

    /* Optional: Ensure border color is consistent with your design */
    .product-tags .react-select__control {
        border-color: #ced4da; /* Example default border color */
        border-radius: 4px; /* Adjust border-radius if needed */
    }
}
