.totals{
    .total-icon{
        background-color: #FFD28040;
        color: #FFA500;
        padding: 7px;
        height: 40px;
        width: 40px;
        border-radius: 9999px;
    }
    .title{
        color: #808080;
        font-weight: 500;
        font-size: 18px;
    }
    .value{
        color: #404040;
        font-weight: 600;
        font-size: 14px;
    }
}