.order-product-table{
    .product-text{
        font-size: 14px;
        color: #1A1A1A;
    }
    .order-product-head th{
        font-size: 12px;
        color: #4D4D4D;
        font-weight: 500;
        background: #BFECC140 !important;
        padding: 10px 50px;
    }
}